<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <template #header>
        <div class="mt-2 w-auto ml-0 mr-auto" id="countries-witohut-name">
          <b-form-checkbox
            :checked="!!filter.without_name"
            @input="setFilter({ without_name: $event })"
            >Countries without name
          </b-form-checkbox>
        </div>
        <div id="countries-with-channels" class="w-auto ml-0 mr-auto mt-2">
          <b-form-checkbox
            :checked="!!filter.with_channel"
            @input="setFilter({ with_channel: $event })"
            >Countries with channels
          </b-form-checkbox>
        </div>
        <b-tooltip
          target="countries-witohut-name"
          variant="info"
          triggers="hover"
        >
          List countries that do not have a name
        </b-tooltip>
        <b-tooltip
          target="countries-with-channels"
          variant="info"
          triggers="hover"
        >
          List countries that have channels
        </b-tooltip>
        <search-input @search="setFilter({ search: $event })"></search-input>
      </template>
      <table-spinner :loading="loading || stateLoading"></table-spinner>
      <b-table
        v-if="!loading && !stateLoading"
        id="country-list"
        class="position-relative"
        :items="data"
        responsive
        primary-key="id"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
      >
        <template #head()="fields">
          <table-header-labels
            :fields="fields"
            :filter="filter"
            @onSort="changePage($event)"
          ></table-header-labels>
        </template>
        <template #cell(actions)="data">
          <b-button
            @click="openEditModal(data.item, $event)"
            variant="outline-primary"
          >
            Edit
          </b-button>
        </template>
      </b-table>
      <template #footer>
        <pagination
          :meta-data="meta"
          @change-page="changePage({ page: $event })"
          @change-per-page="changePerPage({ per_page: $event })"
        >
        </pagination>
      </template>
      <b-modal
        ref="edit-country-modal"
        cancel-variant="secondary"
        ok-title="Accept"
        cancel-title="Cancel"
        centered
        title="Edit country"
        @ok="onSaveCountry"
      >
        <b-form-group v-if="selectedCountry" label="Name" label-for="Name">
          <b-form-input id="name" v-model="selectedCountry.name" />
        </b-form-group>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormCheckbox,
  BTable,
  BButton,
  BModal,
  BFormInput,
  BFormGroup,
  BTooltip,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import Pagination from "@/layouts/components/XmlTvPagination.vue";
import debounce from "lodash.debounce";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import SearchInput from "@/layouts/components/form/SearchInput.vue";
import TableHeaderLabels from "@/layouts/components/TableHeaderLabels.vue";

export default {
  components: {
    BCard,
    BTable,
    BFormCheckbox,
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
    BTooltip,
    Pagination,
    TableSpinner,
    SearchInput,
    TableHeaderLabels,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        { key: "code", label: "Code" },
        { key: "channel_count", label: "Channels" },
        { key: "actions", label: "" },
      ],
      selectedCountry: null,
      loading: false,
    };
  },
  computed: {
    data() {
      return this.$store.getters["adminCountry/getCountryListData"];
    },
    filter() {
      return {
        ...this.$route.query,
        without_name: this.$route.query?.without_name === "true",
        page: this.$route.query?.page ?? 1,
        per_page: this.$route.query?.per_page ?? 10,
        sort_by: this.$route.query?.sort_by ?? "name",
        order_by: this.$route.query?.order_by ?? "desc",
      };
    },
    meta() {
      return this.$store.getters["adminCountry/getCountryListMeta"];
    },
    stateLoading() {
      return this.$store.getters["adminCountry/isLoading"];
    },
  },
  mounted() {
    this.getCountries({ ...this.filter }).catch(console.error);
  },
  methods: {
    ...mapActions({
      getCountries: "adminCountry/getCountries",
      updateCountry: "adminCountry/updateCountry",
    }),
    openEditModal(county) {
      this.selectedCountry = { ...county };
      this.$refs["edit-country-modal"].show();
    },
    changePage(filter = {}) {
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    changePerPage(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    setFilter(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });

      if (filter.search !== undefined) {
        if (filter.search?.length === 0) {
          this.$router.replace({
            query: { ...this.filter, search: undefined },
          });
        }
      }

      this.onRefreshPage();
    },
    onRefreshPage: debounce(async function () {
      this.loading = true;
      try {
        await this.getCountries(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),
    async onSaveCountry() {
      await this.updateCountry(this.selectedCountry);
      this.selectedCountry = null;
      this.onRefreshPage();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
